import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  ActivateAccount,
  LoginResponse,
  LoginResponseData,
  LoginResponseRole2,
  LoginResponseUser,
  Register,
  ResetPasswordResponse,
  UpdatePassword,
} from '@core/models/client/auth';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { RolesAndPermissionsService } from './roles-and-permissions.service';
import { tap, finalize } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);
  private loadingService = inject(LoadingService);
  roleService = inject(RolesAndPermissionsService);
  router = inject(Router);

  BASE_URL = `${environment.ADMIN_BASE_URL}/auth`;
  USER_BASE_URL = `${environment.ADMIN_BASE_URL}`;
  headers = {
    'Content-Type': 'application/json',
  };
  private authTokenKey = 'auth_token';
  private userNameKey = 'user_name';
  private userRoleKey = 'user_role';
  private returnURLKey = 'return_url';
  private currentUserKey = 'current_user';

  constructor() { }

  login(userLoginInfo: {
    email: string;
    password: string;
  }): Observable<LoginResponse> {
    const uniqueStateUrl = 'login';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<LoginResponse>(`${this.BASE_URL}/login`, userLoginInfo, {
        headers: this.headers,
      })
      .pipe(
        tap((response) => {
          if (response.token) {
            this.setToken(response.token);
          }
          this.setCurrentUser(response.data);
          this.setUserRoles(response.data.user.roles);
          this.setCurrentUserData(response.data.user);
        }),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getRefreshToken(): Observable<LoginResponse> {
    return this.http
      .get<LoginResponse>(`${this.USER_BASE_URL}/v1/admin/refresh-token`, {
        headers: this.headers,
      })
      .pipe(
        tap((response) => {
          if (response.token) {
            this.setToken(response.token);
          }
        }),
        // finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  private setToken(token: string): void {
    sessionStorage.setItem(this.authTokenKey, token);
  }

  tokenIsExpired(): boolean {
    const decodedToken = this.getDecodedAccessToken();
    const now = Math.floor(Date.now() / 1000);
    if (decodedToken && (decodedToken?.exp - now) > 0) {
      return true;
    }
    return false;
  }
  getDecodedAccessToken(): any | null {
    try {
      const str = sessionStorage.getItem(this.authTokenKey);
      return str ? jwtDecode(str) : null;
    } catch (Error) {
      return null;
    }
  }

  getToken(): string | null {
    return sessionStorage.getItem(this.authTokenKey);
  }

  storeReturnUrl() {
    const currentUrl = this.router.url;
    if (!this.hasRedirectURL()) {
      sessionStorage.setItem(this.returnURLKey, currentUrl); // Replace with your storage method
    }
  }

  hasRedirectURL(): boolean {
    return sessionStorage.getItem(this.returnURLKey) ? true : false;
  }

  startSession() {
    const returnUrl = sessionStorage.getItem(this.returnURLKey); // Replace with your storage method
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);
      sessionStorage.removeItem(this.returnURLKey); // Clear the stored URL
    } else {
      this.router.navigateByUrl('/admin/dashboard'); // Default path if no return URL
    }
  }

  clearToken(): void {
    sessionStorage.removeItem(this.authTokenKey);
  }

  private setCurrentUserData(user: LoginResponseUser): void {
    sessionStorage.setItem(this.currentUserKey, JSON.stringify(user));
  }
  private setCurrentUser(data: LoginResponseData): void {
    sessionStorage.setItem(this.userNameKey, data.user.name);
  }

  private setUserRoles(roles: LoginResponseRole2[]): void {
    sessionStorage.setItem(this.userRoleKey, JSON.stringify(roles));
  }

  getCurrentUserData(): LoginResponseUser | null {
    const currentUserData = sessionStorage.getItem(this.currentUserKey);
    return currentUserData ? JSON.parse(currentUserData) : null;
  }

  getUserName(): string | null {
    return sessionStorage.getItem(this.userNameKey);
  }

  getUserRoles(): LoginResponseRole2[] | null {
    const userRoles = sessionStorage.getItem(this.userRoleKey);
    return userRoles ? JSON.parse(userRoles) : null;
  }

  clearUserDetails(): void {
    sessionStorage.removeItem(this.userNameKey);
    sessionStorage.removeItem(this.userRoleKey);
    sessionStorage.removeItem(this.currentUserKey);
  }

  logout(): void {
    this.clearToken();
    this.clearUserDetails();
  }

  register(registrationInfo: Register) {
    return this.http.post(`${this.BASE_URL}/signup`, registrationInfo, {
      headers: this.headers,
    });
  }

  activateAccount(otp: ActivateAccount) {
    return this.http.post(this.BASE_URL, otp);
  }

  // API awaiting documentation
  resendOtp() { }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(
      `${this.BASE_URL}/forgot-password`,
      { email },
      { headers: this.headers }
    );
  }

  resetPassword(
    token: string,
    password: string
  ): Observable<ResetPasswordResponse> {
    return this.http.post<ResetPasswordResponse>(
      `${this.BASE_URL}/reset-password`,
      { token, password },
      { headers: this.headers }
    );
  }

  createNewPassword(invite_token: string, password: string): Observable<any> {
    return this.http.post(`${this.BASE_URL}/set-password`, {
      invite_token,
      password,
    });
  }

  updatePassword(updatePasswordInfo: UpdatePassword) {
    return this.http.post(this.BASE_URL, updatePasswordInfo);
  }
}
